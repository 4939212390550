import { GeographyTable } from "@core/interfaces/world-geography.interface";

export const geographyTableData: GeographyTable[] = [
    { tableLabel: 'Land area', amount: 451.9 },
    { tableLabel: 'Land area', amount: 451.9 },
    { tableLabel: 'Water area', amount: 60.1 },
    { tableLabel: 'Water area', amount: 60.1 },
    { tableLabel: 'Land bias', amount: 88.3 },
    { tableLabel: 'Land bias', amount: 88.3 },
    { tableLabel: 'Highest point', amount: 441.0 },
    { tableLabel: 'Highest point', amount: 441.0 },
    { tableLabel: 'Lowest point', amount: 187.5 },
    { tableLabel: 'Lowest point', amount: 187.5 },
    { tableLabel: 'Annual rainfall', amount: 1270},
    { tableLabel: 'Annual rainfall', amount: 1270}
];