<div class="description-section">
    <div class="layout">
        <h1 class="title">{{information.title}}</h1>
        <p>{{information.description}}</p>
        <div class="buttons-wrapper" id="mint">
          <div class="mint-container" *ngIf="isWalletConnected">
            <div class="less" (click)="decreaseMint()" [ngClass]="{disabled: amountToMint <= 0}">-</div>
            <div class="amount">{{amountToMint}}</div>
            <div class="more" (click)="increaseMint()">+</div>
          </div>
            <button class="btn1" *ngIf="isWalletConnected" (click)="mint()">
              Mint Now
            </button>
          <button class="btn1" *ngIf="!isWalletConnected" (click)="connectWallet()">
            Connect Wallet
          </button>
            <button class="btn2" (click)="openDialog()">Whitepaper</button>
        </div>
    </div>
    <picture>
        <source media="(max-width:1225px)" srcset="/assets/images/gallery.png">
        <img class="gallery" src="/assets/images/gallery.png" alt="gallery">
      </picture>
</div>
