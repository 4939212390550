import {Component, OnInit} from '@angular/core';
import {navigateCollection} from './const/navigation-list.const';
import {WalletService} from "../../services/wallet.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public navbarList = navigateCollection;
  public displayNavbar: boolean = false;

  isWalletConnected: boolean;
  walletId: string;

  constructor(private readonly walletService: WalletService) {
  }

  ngOnInit(): void {
    this.walletService.currentAccount.subscribe(account => {
      this.walletId = this.walletService.currentAccount.getValue();
      this.isWalletConnected = this.walletId !== '';
    });
  }

  async onWalletConnect(): Promise<void> {
    // if (this.walletService.isMobile()) {
    //   window.open(`https://metamask.app.link/dapp/https://dimwitsnft.com/`, '_blank', 'noopener,noreferrer');
    //   return;
    // }
    await this.walletService.connect();
  }

  toggleNavbar() {
    this.displayNavbar = !this.displayNavbar;
  }

  closePopup() {
    this.displayNavbar = false;
  }
}
