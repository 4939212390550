<div class="benefits-container">
    <div class="benefits-layout">
        <h1 class="title">{{benefitsDataTypes.title | uppercase}}</h1>
        <img [src]="benefitsDataTypes.titleImage" alt="">
        <p class="details" *ngFor="let detail of benefitsDataTypes.description">{{detail}}</p>
      <div class="labeled-logo-container">
        <a href="https://versadiastudios.com/">
          <img class="minilogo" src="../assets/images/versadia.png" *ngIf="benefitsDataTypes.bottomLabel" />
        </a>
        <a class="label" href="https://stellardev.org/" *ngIf="benefitsDataTypes.bottomLabel">{{benefitsDataTypes.bottomLabel}}</a>
      </div>

    </div>
    <div class="benefits-image-wrapper">
        <img [src]="benefitsDataTypes.image" alt="">
    </div>
</div>
