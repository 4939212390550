import { PlayerBenefits } from "@core/interfaces/benefits.interface";

export const benefitsCollection: PlayerBenefits[] = [
    {
        title: 'play-to-earn on',
        titleImage: '../../../../../assets/svg/nft-worlds.svg',
        image: '../../../../../assets/svg/lego-warriors.svg',
        description: [
            'Explore and earn $WRLD in a familiar landscape, and choose your faction as a DimWit or DimPunk to play with or against each other.',
            'in collaboration with Versadia Studios, EcoStudios, and StellarDev, we’re building unique worlds and play-to-earn games exclusively for DimWits and DimPunks holders.',
            'From Hunger Games to Bed Wars, these popular Minecraft games are customized for DimWits holders and offer a chance to be rewarded simply for participating.'
        ],
        bottomLabel: 'StellarDev'
    },
    {
        title: 'enjoy exclusive content in',
        titleImage: '../../../../../assets/svg/sand-box.svg',
        image: '../../../../../assets/svg/lego-black-man.svg',
        description: [
            'Earn $DIM and collect wearables for your DimWit or DimPunk by playing our custom games in The Sandbox. With multiple gaming and community experiences, this can be as casual or competitive as the player chooses.',
            'Some of these Voxel wearables include armor and weapons that elevate your DimWit, and can be purchased (or sold) with $DIM'
        ],
    },
    {
        title: 'adventure untapped terrain in',
        titleImage: '../../../../../assets/svg/netvrk.svg',
        image: '../../../../../assets/svg/colorful-pipe-man.svg',
        description: [
            'Battle for the metaverse at the Wirehead HQ located in NetVrk’s 3D metaverse.',
            'DimWits are taking a competitive direction in Network, and will feature customized games with the two factions fighting for supremacy. Built on Unreal Engine, this is a next-level metaverse and gaming experience.'
        ],
    },
];





