import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from './icons/icons.module';
import { AccordionModule } from './accordion/accordion.module';
import { PlayerBenefitsModule } from './player-benefits/player-benefits.module';
import { EntityCardModule } from './entity-card/entity-card.module';
import { CounterModule } from './counter/counter.module';
import { AccordionDialogModule } from './accordion-dialog/accordion-dialog.module';


@NgModule({
  imports: [
    CommonModule,
    IconsModule,
    PlayerBenefitsModule,
    EntityCardModule,
    CounterModule,
    AccordionDialogModule
  ],
  exports: [
    IconsModule,
    PlayerBenefitsModule,
    AccordionModule,
    EntityCardModule,
    CounterModule,
    AccordionDialogModule
  ]
})
export class SharedModule { }
