import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-player-benefits',
  templateUrl: './player-benefits.component.html',
  styleUrls: ['./player-benefits.component.scss']
})
export class PlayerBenefitsComponent implements OnInit {

  @Input() benefitsDataTypes;
  
  constructor() { }

  ngOnInit(): void {
  }

}
