import { BaseInformation } from '@core/interfaces/information.interface';

export const whitePaperAccordion: BaseInformation[] = [
    {title: 'Preface', description: [`As the speculative era of buying NFT’s is coming to an end, we expect utility in NFT’s to be an absolute must for consumer interest. We also expect the metaverse to expand well beyond the current phase. As the concept of the metaverse is still in its infancy, this rapid growth we’ve all been seeing hasn’t slowed down in the slightest.
    Because of this, we believe that there will never be “one metaverse.” Many projects go all-in on a single Metaverse, with no plans to adapt beyond that.
    We created The Wirehead DAO and DimWits to solve this problem. 
    A DimWits NFT acts as membership to The Wirehead DAO. The Wirehead DAO acquires NFTs and digital real-estate voted on by its members through proposals, with an option to sell certain land to acquire others.
    The goal of this is to give The Wirehead DAO members (DimWits holders) a voice in all of the utility, perks, exclusives and more of the Metaverse experience that DimWits and DimPunks has to offer. Not only a voice, but the sole decision makers in our development. 
    We believe that having this DAO is essential to keeping the community strong. The one common theme that all strong NFT projects have is a strong community. While hype often ensures a smooth and profitable mint, if the community feels unheard by the founders of a project it will almost certainly fall apart. This DAO gives them that voice to be heard.
    `]},

    {title: 'The Wirehead DAO (Decentralized Autonomous Organization)', description: [`“Wirehead” is the licensed LLC that DimWits and DimPunks operate under. The Wirehead DAO is structured in such a way that you are only required to own at least 1 DimWit to be a member. For each DimWit you own, you get 1 vote on where the funds in the community wallet are allocated and when assets are liquidated.
    The Wirehead DAO is the main governing body of DimWits and the Wirehead DAO’s NFTs, as well as acquisition and liquidation decisions. 
    DAO votes may be delegated, but by default will be allocated to each DimWit NFT holder's address and transferred to new owner addresses upon a sale, regardless of the existing delegation.`,
    '1 DimWit NFT = 1 DAO Vote',
    '2 DimWits NFTs = 2 DAO Votes',
    'And so on.'
  ]},

    {title: 'DimWits Mint', description: [
      'DimWits are 0.05 ETH each to mint.',
      `There will be 4,000 DimWits available to mint. The reason we selected 4,000 was to offer many members the opportunity to join the DimWits ecosystem without oversaturating our own market. 
      Some DimWits will be bought back by the community wallet (the DAO wallet, 0x5792CDA9c9AE5d647a3E377A0CD448dd3FbFD04D). 
      35% of all mint sales will be allocated to the DAO for NFT (Land) acquisition as voted on by DAO members.`,
      'Another 20% will be added to the $DIM liquidity pool.',
      `The remaining 45% will be allocated to the community and engineering fund. These funds will be used to ramp up the engineering team underneath the engineering leadership (John and Rusty) to aid in building out the core tech, dApps and play-to-earn games to streamline the management of DAO governance, proxy utility, and perks of acquired NFTs to DAO members. 
      Additionally, the community and engineering fund will also be used to drive community growth, visibility and awareness of the project as these types of proposals are presented to the DAO by the team.`
  ]},

    {title: 'DimPunks Mint', description: [
      `DimPunks are free to mint to anyone holding at least 2 DimWits. Meaning, if you hold 4 DimWits, you may mint 2 DimPunks for free.
      After 24 hours, the remaining DimPunks that haven’t been minted by DimWits holders will be available for a public sale at .08 ETH
      The total supply for the DimPunks will be 7,777
      20% of all DimPunks mint sales will be put directly into the $DIM liquidity pool. 50% will be allocated to the DAO. The remaining 30% will be allocated to the engineering team for development.`
    ]},

    {title: 'DimPunks', description: [
      `DimPunks is a separate collection of cyberpunk themed NFTs and will contribute to the original DimWits ecosystem. While DimPunks are free to mint for every 2 DimWits owned, we chose to offer the remaining DimPunks for sale, up to 7,777.
      Since the development of DimPunks began after the DimWits mint, we have much more time and money to work with. Rather than 1 head shape, there will be multiple shapes and will include both male and female variations. 
      `
    ]},

    {title: 'The $DIM Token', description: [
      `$DIM is the native token to the DimWits ecosystem. $DIM can be earned through staking your DimWit or DimPunk. With $DIM, you can purchase wearables for your DimWits or DimPunks, most notably accessories or weapons for your VX DimWit in The Sandbox. 
      In future limited drops, merch can only be purchased with $DIM. Same with future VX collections (wearables).
      ETH liquidity paired for $DIM will be added to Uniswap after the completion of the DimWits mint, and DimWits will continue to add liquidity through aftermarket sales along with the DimPunks mint. This means that you will eventually be able to buy $DIM with ETH, and vice versa.
      Approximately 2.3 $DIM will be generated per day for each DimWit and DimPunk staked. So, if you are staking 10 DimWits, you will be earning about 23 $DIM a day. This is the only way that $DIM can be generated, and the team cannot increase the supply. However, all DimWits in the community wallet will be staked to add $DIM to the liquidity pool in Uniswap.`
    ]},

    {title: 'The Metaverse', description: [
      `While the “Metaverse” has become quite the buzzword this year, the possibilities are truly endless. That’s why we created the Wirehead DAO, so we aren’t a burden to ourselves being stuck with just one. The DimWits team has already purchased digital real-estate and plans to build on whichever platforms the DAO chooses. 
      While DimWits has already began preparation for The Sandbox, Netvrk, and NFT Worlds, we foresee many more to come. That’s why it’s so important for us to keep the $DIM token native, so it can create value in every ecosystem DimWits decides to venture into. 
      However, some Metaverses have their own native token, example being NFT Worlds. Since theirs is the $WRLD token, DimWits players will earn $WRLD and $DIM through participating in our content there.`
    ]},

    {title: 'DimWits and DimPunks Content', description: [
      `The DimWits and DimPunks ecosystem will be native. For example, by holding both, you will have the ability to stake $DIM and use the token to purchase wearables or other collectables for either DimWits or DimPunks.
      However, some content that DimWits holders enjoy will be exclusive to DimWits, and same goes for DimPunks.
      Some of this content includes play-to-earn gaming or exclusive metaverse land. There will also be content where the 2 factions can socialize and play with (or against) each other.`
    ]},

    {title: 'Play-to-Earn Gaming', description: [
      `Since DimWits main-focus is on the Metaverse in general, a major aspect of our community will be our involvement in play-to-earn gaming. Our greatest example of this will be our plans to build on NFT Worlds. Where just for the community’s participation in playing, they will be earning $WRLD – the native token to the NFT Worlds ecosystem. 
      On top of this, while $DIM can be earned passively just by staking your DimWit, it can also be earned through participating in The Sandbox. Our intentions on The Sandbox are slightly different, however. Here, DimWits is currently developing games where you can wager your items purchased with $DIM against other players.
      For example, if you purchase a sword for your DimWit with 100 $DIM, and another player eliminates you in a game, he’d be able to take your item and choose to either keep it or sell it for $DIM. While this might understandably turn some people off, there will also be less risky content in here as well. 
      While these are just examples, we have plans to expand to as many metaverses as the community decides and won’t be restrained to just one.`
    ]},

    {title: 'Liquidity And NFT Acquisition', description: [
      `Acquisition of NFT’s (land) and liquidation shall be done through DAO proposals. 
      NFTs (land) liquidated shall have funds returned to the DAO for future acquisition and utilization as proposed by the DAO. 
      The community wallet will also own DimWits NFT’s, and the DAO may vote on us buying or even selling DimWits to fund development, when proposed by the DAO.
      For example, if DimWits is involved in a metaverse that the community no longer feels interested in, they may hold a vote and decide that DimWits sells all assets relating to that metaverse, including land, and then use those funds to develop in another metaverse instead.`
    ]},

    {title: 'Secondary Market Royalties', description: ['With a 5% aftermarket royalty, 50% of all secondary market royalties will be deposited into the DAO, as well as adding liquidity to the $DIM token. The remaining 50% will be distributed to the engineering team and community fund to further the project’s growth and development, and of course take care of our mods.']},
  
    {title: 'How Will Discrepancies and Governance Be Handled?', description: [
      `After the completion of the DimWits mint, the DAO will ultimately have full control and decision over any discrepancies and project direction not outlined in the whitepaper. 
      This Whitepaper is simply an outline of our goals and intentions with the project, and is a foundation. The community ultimately decides which direction the content goes.`
    ]},
  
    {title: 'I’m lazy. What’s the TLDR?', description: [
      `By owning at least 1 DimWit NFT, you are a member of The Wirehead DAO - a decentralized organization that buys and sells NFTs proposed by and voted on by DAO members in order to give access to more benefits for all members. 
      You will also have a say in where our funds within the DAO community wallet are allocated, including selling our own NFT’s to acquire others, or fund development. 
      This is possible through a guarded signature delegation system that lets you prove ownership when attempting to connect to projects via Metamask to “prove” ownership and cast a vote.
      All of this will be as simple and automated as connecting your wallet to a dApp with Metamask and signing a transaction (without a gas fee), as most are used to now.`
    ]}
  ];