<div class="collection-section">
    <div class="collection-header">
        <h1>{{selectionListData.title}}</h1>
        <p>{{selectionListData.secondaryTitle}}</p>
        <ul><li class="collection-item" *ngFor="let data of selectionListData.list">{{data.item}}</li></ul>
    </div>
        <div class="image-content-wrapper">
            <img class="collection-image" [src]="selectionListData.image.path" [alt]="selectionListData.image.alt" />
        </div>
        <ng-content></ng-content>
</div>