import { Accordion } from '@core/interfaces/accordion.interface';

export const accordion: Accordion[] = [
  {
    title: 'What are DimWits?',
    description:[
      'DimWits are NFT’s stored on the Ethereum blockchain. Like BAYC or CryptoPunks, they are randomly generated with their own unique properties. No 2 DimWits are the same.',
      'Each DimWit will be your ticket to exclusive content such as Metaverse gaming, staking, and more.'
    ],
  },

  {
    title: 'How are DimWits created?',
    description: ['DimWits are hand drawn by our in-house artist Sylr. He digitally created each trait, and we randomly generate them. Some are more rare than others, but no 2 are the same.']
  },

  {
    title: 'How many DimWits are there?',
    description: ['There will only ever be 4,000 DimWits.'],
  },

  {
    title: 'How do I get a DimPunk?',
    description: [
      'To mint a DimPunk, you’ll have to hold at least 2 DimWits in your wallet. For every 2 DimWits you own, you’ll be able to mint 1 free DimPunk.',
      'All remaining DimPunks that haven’t been minted by the DimWits holders will be available for a public sale. The total supply for them will be 7,777.'
    ]
  },

  {
    title: 'Would would I pay for a jpeg?',
    description: ['You aren’t just paying for the art, even though it’s fire. You will have digital ownership of 1 ticket which gives you exclusive content for life. Also, the more DimWits you own, the more $DIM you’ll be able to stake.'],
  },

  {
    title: 'What’s the utility for DimWits?',
    description: [
      `1. Holding 2 DimWits give you access to mint a free DimPunk<br />
      2. You get a free 1:1 VX version of your DimWit and/or DimPunk to be a playable character in The Metaverse.<br />
      3. Staking a DimWit or DimPunk allows you to collect $DIM, where you can buy items such as merch or wearables for your VX DimWit. You also can swap your $DIM for ETH.<br />
      4. By simply participating in our custom world in NFT Worlds, you’ll earn $WRLD`
    ]
  },

  {
    title: 'What is the $DIM token?',
    description: [
      'The $DIM token will be our native token to the DimWit ecosystem. If you hold a DimWit or DimPunk, you’ll stake $DIM and be able to use that to redeem rewards such as merch or future mints.',
      'We also are implementing the $DIM token to be used in the Metaverse. An example of this is the ability to purchase weapons and wearables in NFT Worlds and The Sandbox.'
    ]
  },

  {
    title: 'Are DimWits on the Metaverse?',
    description: ['Yes. DimWits are currently building on The Sandbox, NetVrk, and NFT Worlds.']
  },

  {
    title: 'What is the DAO?',
    description: [
      'The Wirehead DAO is the DAO that supports everything involving the DimWits ecosystem. As the metaverse changes, we’d be shooting ourselves in the foot by going all-in on just one.',
      'That’s why we created the Wirehead DAO - to let the community decide where and what we build.',
      '1 DimWit = 1 DAO vote'
    ]
  },

  {
    title: 'What was the inspiration behind the art?',
    description: [
      'John and Sylr bounced ideas off each other for a while before coming up with the beautiful base DimWit. John wanted something low-brow, someone that looks like they’d willingly stick a microchip inside their brain to embrace the new world.',
      'The first thing Sylr thought of: give him a really big schnoz.'
    ]
  },

  {
    title: 'Can I screenshot a DimWit?',
    description: ['FUCK NO!']
  }
];
