import { GeographyTable } from '@core/interfaces/world-geography.interface';
import { Accordion } from '@core/interfaces/accordion.interface';
import { Component, OnInit } from '@angular/core';
import { listTypeCollection } from '../../const/collection-list.const'
import { teamCards } from './../../const/entity-card.const';
import { benefitsCollection } from '../../const/player-benefits.const';
import { PlayerBenefits } from '@core/interfaces/benefits.interface';
import { Collection } from '@core/interfaces/dim-collection.interface';
import { TeamEntityCard } from '@core/interfaces/team-card-entity.interface';
import { moderatorsCards } from './../../const/entity-card.const';
import { accordion } from '../../const/accordion.const';
import { geographyTableData } from '../../const/geography-table.const';
import {WalletService} from "../../../../../services/wallet.service";
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public collectionSection: Collection = listTypeCollection;
  public benefits: PlayerBenefits[] = benefitsCollection;
  public entityCards: TeamEntityCard[] = teamCards;
  public moderatorsCards: TeamEntityCard[] = moderatorsCards;
  public accordion: Accordion[] = accordion;
  public GeographyTable: GeographyTable[] = geographyTableData;

  public uniqueFeaturesButtons: string[] = ['Monolith', 'Snow', 'Mountains', 'Oil fields', 'Ore rich', 'Ancient forest'];

  isWalletConnected: boolean;
  constructor(private readonly walletService: WalletService) { }

  ngOnInit(): void {
    this.walletService.currentAccount.subscribe(() => {
      const walletId = this.walletService.currentAccount.getValue();
      this.isWalletConnected = walletId !== '';
    });
  }

  openOpenSeaBuy() {
    window.open('https://opensea.io/collection/dimwits', '_blank');
  }

  goToMint() {
    document.getElementById('mint').scrollIntoView();
  }
}
