import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openSmartContractAddress() {
    window.open('https://etherscan.io/address/0x1a4c4b22fa54b65a0bce5f4ea4a33378c007726e', '_blank');
  }
}
