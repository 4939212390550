import { FooterModule } from './../../../feature-modules/footer/footer.module';
import { NavbarModule } from './../../../feature-modules/navbar/navbar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainRoutingModule } from './main-routing.module';
import { HomeModule } from '../home/home.module';
import { MainComponent } from './component/main.component';


@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    MainRoutingModule,
    HomeModule,
    NavbarModule,
    FooterModule,
  ]
})
export class MainModule { }
