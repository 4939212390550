import { Component, OnInit } from '@angular/core';
import { icons } from './const/social-media-icon.const';
import {Icons} from "@shared/icons/interfaces/social-media-icon.interface";

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent implements OnInit {
  public socialMediaIcons = icons;

  constructor() { }

  ngOnInit(): void {
  }

  onLinkClick(icon: Icons) {
    if (icon.link) {
      window.open(icon.link, '_blank');
    }
  }
}
