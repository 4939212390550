<div [class.active]="idx === currentOpenAccordionId" [@bgColor]="idx !== currentOpenAccordionId ? 'close' : 'open'" class="accordion" [ngClass]="{isOpen: idx ===  currentOpenAccordionId}" *ngFor="let data of accordionData; let idx = index" (click)="toggleAccordion(idx)">
    <div class="accordion-label">
        <span>{{data.title}}</span>
        <ng-template [ngIf]="idx === currentOpenAccordionId">
            <img src="assets/svg/minus.svg" alt="">
        </ng-template>
        <ng-template [ngIf]="idx !== currentOpenAccordionId">
            <picture>
                <source media="(max-width: 1024px)" srcset="/assets/svg/gray-plus.svg">
                <img src="assets/svg/plus.svg" alt="">
            </picture>
        </ng-template>
    </div>
    <div [@smoothCollapse]="idx !== currentOpenAccordionId? 'close' : 'open'" class="accordion-details">
        <p *ngFor="let accordionDetails of data.description" [innerHTML]="accordionDetails"></p>
    </div>
</div>
