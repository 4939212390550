<div class="card-wrapper">
    <div [ngStyle]="{'max-width.px': maxImageSize}" class="test">
        <img class="card-image" [src]="entityCardData.entityImage" alt="">
    </div>
    <div>
        <h1 class="entity-name">{{entityCardData.nickName}}</h1>
        <label class="entity-role" *ngIf="entityCardData.role">{{entityCardData.role}}</label>
        <label class="entity-role" *ngIf="entityCardData.entityId">{{entityCardData.entityId}}</label>
    </div>
    <p class="slogan">{{entityCardData.slogan}}</p>
    <div class="icon-wrapper">
        <a *ngFor="let icon of entityCardData.iconLink" [href]="icon.path">
            <img [src]="icon.icon" alt="">
        </a>
    </div>
</div>