import { geographyTableData } from './../../const/geography-table.const';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-world-geography',
  templateUrl: './world-geography.component.html',
  styleUrls: ['./world-geography.component.scss']
})
export class WorldGeographyComponent implements OnInit {
  @Input() geographyTableRow;

  constructor() { }

  ngOnInit(): void {
  }

}
