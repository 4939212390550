import { Collection } from '@core/interfaces/dim-collection.interface';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-collection-list',
  templateUrl: './collection-list.component.html',
  styleUrls: ['./collection-list.component.scss']
})
export class CollectionListComponent implements OnInit {
  @Input() selectionListData;

  constructor() { }

  ngOnInit(): void {
  }

}
