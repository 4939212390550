import { TeamEntityCard } from "@core/interfaces/team-card-entity.interface";

export const teamCards: TeamEntityCard[] = [
    {
        entityImage: 'assets/images/John.png',
        nickName: 'john',
        role: 'Founder',
        slogan: 'Certified NFT degen.',
        iconLink: [
            {icon: 'assets/svg/twitter-icon.svg', path: 'https://twitter.com/johnslonk'},
        ]
        // iconLink: ['assets/svg/twitter-icon.svg']
    },
    {
        entityImage: 'assets/images/Sylr.png',
        nickName: 'sylr',
        role: 'Artist',
        slogan: '80’s film enjoyer.',
        iconLink: [
            {icon: 'assets/svg/twitter-icon.svg', path: 'https://twitter.com/artofsylr'},
            {icon: 'assets/svg/shape.svg', path: 'https://sylrillustrationstudios.com'}
        ]
    },
    {
        entityImage: 'assets/images/Rusty.png',
        nickName: 'rusty',
        role: 'Head Dev',
        slogan: 'Thank him for the cheap(er) gas',
        iconLink: [
            {icon: 'assets/svg/twitter-icon.svg', path: 'https://twitter.com/otc_rusty'},
            {icon: 'assets/svg/shape.svg', path: 'https://github.com/Rubberbandits'}
        ]
    },
    {
        entityImage: 'assets/images/Klyk.png',
        nickName: 'klyk',
        role: 'Lead animator',
        slogan: 'Probably on Blender'
    },
    {
        entityImage: 'assets/images/LiquidationSwords.png',
        nickName: 'liquidationswords',
        role: 'Community Manager',
        slogan: 'Hip-Hop Connoisseur. Keeps the Discord weird.',
        iconLink: [
            {icon: 'assets/svg/twitter-icon.svg', path: 'https://twitter.com/sikwitrythym'},
        ]
    },
    {
        entityImage: 'assets/images/James.png',
        nickName: 'james',
        role: 'Discord Dev/ Head Mod',
        slogan: 'Protects us from bad actors.',
    },
];

export const moderatorsCards: TeamEntityCard[] = [
    {
        entityImage: 'assets/images/Bryce.png',
        nickName: 'Bryce',
        entityId: 'Bryce#4459'

    },
    {
        entityImage: 'assets/images/Altec.png',
        nickName: 'altec',
        entityId: 'Altec#8936'
    },
    {
        entityImage: 'assets/images/Simbro.png',
        nickName: 'simbro',
        entityId: 'simbro(∞)#5555'
    }
];

