import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionDialogComponent } from './component/accordion-dialog.component';
import { AccordionModule } from '@shared/accordion/accordion.module';



@NgModule({
  declarations: [AccordionDialogComponent],
  imports: [
    CommonModule,
    AccordionModule
  ],
  exports: [AccordionDialogComponent]
})
export class AccordionDialogModule { }
