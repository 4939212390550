import { BaseInformation } from '@core/interfaces/information.interface';
import { AccordionDialogComponent } from '@shared/accordion-dialog/component/accordion-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { mainInfo } from '../../const/main-info.const';
import { whitePaperAccordion } from '../../const/whitepaper-accordion-modal.cost';
import {WalletService} from "../../../../../services/wallet.service";
@Component({
  selector: 'app-main-information',
  templateUrl: './main-information.component.html',
  styleUrls: ['./main-information.component.scss']
})
export class MainInformationComponent implements OnInit {
  public information = mainInfo;
  accordionModal: BaseInformation[] = whitePaperAccordion;
  isWalletConnected: boolean;
  walletId: string;
  amountToMint: number = 0;
  constructor(private dialog: MatDialog, private readonly walletService: WalletService) { }

  ngOnInit(): void {
    this.walletService.currentAccount.subscribe(account => {
      this.walletId = this.walletService.currentAccount.getValue();
      this.isWalletConnected = this.walletId !== '';
    });
  }

  openDialog() {
    this.dialog.open(AccordionDialogComponent, {
      width: '100%',
      maxWidth: '1000px',
      height: '80%',
      data: this.accordionModal
    });
  }

  async connectWallet() {
    // if (this.walletService.isMobile()) {
    //   window.open(`https://metamask.app.link/dapp/https://dimwitsnft.com/`, '_blank', 'noopener,noreferrer');
    //   return;
    // }
    await this.walletService.connect();
  }

  mint() {
    if (this.amountToMint <= 0) {
      alert('Please select value higher than 0');
      return;
    }
    this.walletService.mint(this.amountToMint);
  }

  increaseMint() {
    this.amountToMint++;
  }

  decreaseMint() {
    if (this.amountToMint <= 0) {
      return;
    }
    this.amountToMint--;
  }
}
