<div class="top-background">
    <div class="top-sections">
        <section>
            <app-main-information></app-main-information>
        </section>
        <section>
            <div class="collections">
                <div class="collection-type">
                <div class="collection-image-title dimwits-image">
                    <img class="dimwits-image" src="assets/svg/dimwits-logo.svg" alt="dimwits-logo">
                </div>
                        <app-collection-list [selectionListData]="collectionSection.dimwits">
                            <div class="extra-content">
                                <button *ngIf="isWalletConnected" class="btn1" (click)="goToMint()">Mint Now</button>
                                <button class="btn2" (click)="openOpenSeaBuy()">Buy on Opensea</button>
                            </div>
                        </app-collection-list>
                </div>
                <div class="collection-type">
                    <div class="collection-image-title dimpunks-image">
                        <img src="assets/svg/dim.svg" alt="dimwits-logo">
                        <img src="assets/svg/punks.svg" alt="dimwits-logo">
                    </div>
                        <app-collection-list [selectionListData]="collectionSection.dimPunks">
                            <div class="extra-content">
                                <span>Mint in late 2022</span>
                            </div>
                        </app-collection-list>
                </div>
            </div>
        </section>

        <section>
            <div class="metaverse-section">
                <h1 class="metaverse-header" id="metaverse">Enter the Metaverse</h1>
                <p class="metaverse-info">DimWits are a completely community-driven and metaverse oriented project
                    planning to take over the Metaverse.
                    While the world cowers in fear, us DimWits embrace our tech overlords. In fact, each DimWit will
                    literally stick
                    a microchip in their brain (airdropped to holders) to become a DimPunk. Since we’re all DimWits, we
                    don’t take
                    ourselves too seriously. </p>
                <p class="metaverse-info">Oh yeah, you’ll also get paid to play.</p>
            </div>
        </section>

        <section>
            <ng-container *ngFor="let benefitsType of benefits">
                <app-player-benefits [benefitsDataTypes]="benefitsType"></app-player-benefits>
            </ng-container>
        </section>
    </div>
</div>

<div class="bottom-background-sections">
    <div class="bottom-sections">
        <section class="dimcity-bg-image">
            <div class="dimcity">
                <div class="dimcity-title-wrapper">
                    <h1>dimcity</h1>
                    <span class="dimcity-rank">World #6305</span>
                    <p class="url">nftworlds.com/6305</p>
                </div>
                <div class="dimcity-content-wrapper">
                    <div class="dimcity-geography-wrapper">
                        <div>
                            <span class="geography-title">World geography</span>
                        </div>
                        <div class="dimcity-geography">
                            <app-world-geography *ngFor="let tableRow of GeographyTable" [geographyTableRow]="tableRow"></app-world-geography>
                        </div>
                    </div>
                    <div>
                        <span class="unique-feature-title">Unique features</span>
                        <div class="unique-buttons">
                            <div class="feature-button-wrapper" *ngFor="let button of uniqueFeaturesButtons">
                                <button class="feature-button">{{button}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="roadmap-section" id="roadmap">
                <h1 class="roadmap-title">roadmap</h1>
                <app-roadmap></app-roadmap>
            </div>
        </section>

        <section>
            <div class="accordion-section-wrapper">
                <div>
                    <h1 class="accordion-title" id="faq">faq</h1>
                </div>
                <ng-container>
                    <app-accordion [accordionData]="accordion"></app-accordion>
                </ng-container>
            </div>
        </section>

        <section>
            <div>
                <h1 class="team-cards-title" id="team">team</h1>
            </div>
            <div class="team-cards">
                <app-card *ngFor="let card of entityCards" [entityCardData]="card"></app-card>
            </div>
            <div>
                <h1 class="moderators-cards-title">moderators</h1>
                <div class="moderators-cards">
                    <app-card *ngFor="let card of moderatorsCards" [entityCardData]="card" [maxImageSize]="200">
                    </app-card>
                </div>
            </div>
        </section>
    </div>
</div>
