<header>
    <div class="navbar-section">
        <img class="logo" src="assets/svg/dimwits-logo.svg" alt="" />
        <nav class="navigation-wrapper">
            <ul class="navigation">
              <ng-container *ngIf="!isWalletConnected">
                <li>
                  <a class="navigation-link" (click)="onWalletConnect()">Connect Wallet</a>
                </li>
              </ng-container>
              <ng-container *ngIf="isWalletConnected">
                <li>
                  <a class="navigation-link">Wallet connected</a>
                </li>
              </ng-container>
                <li *ngFor="let item of navbarList">
                    <a class="navigation-link" href="{{item.path}}">{{item.label}}</a>
                </li>
            </ul>
            <div class="icons">
                <app-icons></app-icons>
            </div>
        </nav>
    </div>
    <div>
        <img class="menu-icon" src="assets/svg/menu-icon.svg" alt="" (click)="toggleNavbar()">
        <ng-template [ngIf]="displayNavbar">
            <div class="navigation-popup">
                <img class="popup-exit-btn" src="assets/svg/exit-navbar.svg" alt="" (click)="closePopup()">
                <ul class="navigation-popup-list">
                  <li class="navigation-popup-item" (click)="onWalletConnect()">
                    <a class="navigation-link">wallet-connect</a>
                  </li>
                    <li class="navigation-popup-item" *ngFor="let item of navbarList">
                        <a class="navigation-popup-link" href="{{item.path}}">{{item.label}}</a>
                    </li>
                </ul>
            </div>
        </ng-template>
    </div>
</header>
