import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.scss']
})
export class RoadmapComponent implements OnInit {

  public roadmapData = [
    {
      image: '/assets/svg/small-gun.svg',
      label: 'Q1 2022',
      title: '$DIM Token Development VX Collection Development',
      details: [
        'Our trusty dev Rusty begins the process of creating the native $DIM token.',
        'DimWits will also begin production of our Voxel collection. This will be a 1:1 3D variation of every DimWit. They will be playable characters in “The Sandbox” Metaverse while also being their own unique NFT. After the collection is complete, we will create “wearables” that will be airdropped to holders and offer free mints that can be traded to other DimWits holders.',
        'This is also when DimWits will launch the first game on NFT Worlds. This will be a play-to-earn experience, and will be expanded upon in later releases.'
      ]
    },
    {
      image: '/assets/svg/purple-rifle.svg',
      label: 'Q2 2022',
      title: 'Metaverse Development',
      details: [
        'The DimWits team will begin allocating funds to develop games and content for all holders within NFT Worlds and The Sandbox Metaverses. As the first game for NFT Worlds will be completed at this point, DimWits plan to develop multiple games on multiple words.',
        'As the Metaverse expands, DimWits will be vigilant on being implemented in as many ways as possible, including further development of the Unreal Engine DimWits and Voxel DimWits, along with Minecraft skins.'
      ]
    },
    {
      image: '/assets/svg/white-rifle.svg',
      label: 'Q3 2022',
      title: 'DimPunks Mint $DIM Token Marketplace',
      details: [
        'We finally mint DimPunks. We also will begin the process of creating a marketplace for the $DIM token. The token will be staked by all DimWit-ecosystem holders. The marketplace will allow for the user to spend their tokens on exclusive NFT’s, starting with The Sandbox wearables.',
        'There will be several marketplaces depending on the specific metaverse. To keep specific metaverses such as NFT Worlds native, $DIM will be paired with $WRLD. This is so while DimWits holders will be rewarded $WRLD for playing, they can choose to swap it for $DIM if they choose.'
      ]
    },
    {
      image: '/assets/svg/black-rifle.svg',
      label: 'Q4 2022',
      title: 'DimPunk Metaverse Development',
      details: [
        'The team begins developing Metaverse content for DimPunks, each with their own exclusive content. Some of this content will be accessible by the other parties, some not. Much of this content will involve factions, so DimWits and DimPunks will be able to play with or against each other.',
        'Much of the DimWits content will be completed by then, but the content will never be “finished.” DimPunks will essentially be an expansion of content.'
      ]
    },
    {
      image: '/assets/images/knife.png',
      label: 'BEYOND! 2022',
      details: ['As the Metaverse and NFT’s in general expand, so will the DimWits ecosystem. We are lucky enough to have a big team of people who love web3 and NFT’s/Crypto in general who are always willing to take this to the next level. That being said, the options are truly limitless for what DimWits can achieve long term with a strong enough community.']
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}




