import { Collection }  from '@core/interfaces/dim-collection.interface';

export const listTypeCollection: Collection = {
    dimwits: {
        title: 'Genesis Collection',
        secondaryTitle: '4,000 glorious hand-drawn DimWits by the legendary artist Sylr.',
        list: [
            { item: 'Access to the DimWits Sandbox and NFT Worlds experience' },
            { item: 'Earn $DIM passively' },
            { item: 'Eligible for free 3D Voxel DimWits that serve as both an NFT and playable character in The Sandbox and other metaverses' },
            { item: 'Access Play-To-Earn gaming' },
            { item: 'Eligible for free Airdrops, including extra wearables for your Voxel DimWit' }
        ],
        image: { path: 'https://cdn.discordapp.com/attachments/968080103210897428/971834788669452328/DimWits.gif', alt: 'satan' }
     },
    dimPunks: {
        title: 'Expansion collection',
        secondaryTitle: '7,777 CyberPunk-themed collection',
        list: [
            { item: 'Same benefits as DimWits, as the whole ecosystem is native' },
            { item: 'Exclusive CyberPunk-themed Sandbox plot' },
            { item: 'Additional CyberPunk-themed wearables AirDropped to stakers' }
        ],
        image: { path: 'https://cdn.discordapp.com/attachments/968080103210897428/971836040446894150/DimPunks.gif', alt: 'dimpunks' }
    }
};
