import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';

import { HomeComponent } from './components/main/home.component';
import { MainInformationComponent } from './components/main-information/main-information.component';
import { CollectionListComponent } from './components/dimwits-collection/collection-list.component';
import { RoadmapComponent } from './components/roadmap/roadmap.component';
import { WorldGeographyComponent } from './components/world-geography/world-geography.component';

@NgModule({
  declarations: [
    HomeComponent,
    MainInformationComponent,
    CollectionListComponent,
    RoadmapComponent,
    WorldGeographyComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatDialogModule,
    SharedModule
  ],
  exports: [
    HomeComponent,
  ]
})
export class HomeModule { }
