import { Accordion } from '@core/interfaces/accordion.interface';
import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [
    trigger('smoothCollapse', [
      state('close', style({
          height: '0',
          overflow: 'hidden'
        })
      ),
      state('open', style({
          overflow: 'visible',
        })),
        transition('close<=>open', animate('200ms'))
    ]),
    trigger('bgColor', [
      state('close', style({
        background: 'transparent'
      })
    ),
    state('open', style({
        background: '#5A43A4',
      })),
    ])
  ]
})

export class AccordionComponent implements OnInit {
  
  @Input() accordionData;
  currentOpenAccordionId: number = -1;

  constructor() { }

  ngOnInit(): void {
  }

  toggleAccordion(index: number) {
    if (index === this.currentOpenAccordionId) {
      this.currentOpenAccordionId = -1;
      return;
    }
    this.currentOpenAccordionId = index;
  }
}
