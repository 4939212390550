import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import ('../pages/modules/main/main.module').then(m => m.MainModule)  
  },
  {
    path: 'wallet',
    loadChildren: () => import ('../pages/modules/wallet/wallet.module').then(m => m.WalletModule)
  },
  {
    path: 'connect-wallet',
    loadChildren: () => import ('../pages/modules/connect-wallet/connect-wallet.module').then(m => m.ConnectWalletModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
