import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayerBenefitsComponent } from './component/player-benefits.component';



@NgModule({
  declarations: [PlayerBenefitsComponent],
  imports: [
    CommonModule
  ],
  exports: [PlayerBenefitsComponent]
})
export class PlayerBenefitsModule { }
