import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsComponent } from './icons.component';



@NgModule({
  declarations: [IconsComponent],
  imports: [
    CommonModule
  ],
  exports: [IconsComponent]
})
export class IconsModule { }
