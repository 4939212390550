import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  showMetadata: boolean;

  constructor(private readonly route: ActivatedRoute) { }

  ngOnInit(): void {
    if ( this.route.snapshot.data['includeMeta'] === undefined) {
      this.showMetadata = true;
      return;
    }
    this.showMetadata = this.route.snapshot.data['includeMeta'];
  }

}
